html, body{
  height: 90%;
  background-color: #df928e;
}



.center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.heart-image{
  background-repeat: no-repeat;
  background-size: cover;
  width:500px;
  height:500px;
  text-align: center;
}


.font-main{
  font-family: 'Dancing Script', cursive;
  color: #1d201f;
  font-size: 2.6rem;
}

@media screen and (max-width:520px){
  .heart-image{
    background-size: cover;
    width:300px;
    height:300px;
    text-align: center;
  }

  .font-main{
    font-family: 'Dancing Script', cursive;
    color: #1d201f;
    font-size: 1.6rem;
  }
}